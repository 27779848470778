import * as React from 'react';
import { mkContext } from './mkContext';
import { useValue } from './hooks';
import { ValueIface } from 'src/util/observe/Value';

interface Response<R> {
  Get: React.Context<NonNullable<R>>;
  OrElse: React.Context<R>;
  Provider: React.FC<PropsIface>;
}

interface PropsIface {
  fallback?: React.ReactNode;
}

interface MkWithFallbackArgs<R> {
  Get: React.Context<NonNullable<R>>;
  OrElse: React.Context<R>;
  value: ValueIface<R>;
}

function mkWithFallback<R>(args: MkWithFallbackArgs<R>): React.FC<PropsIface> {
  const { Get, OrElse } = args;
  return (props) => {
    const value = useValue(args.value);
    if (value != null) {
      return <Get.Provider value={value as NonNullable<R>}>{props.children}</Get.Provider>;
    } else {
      return <OrElse.Provider value={value}>{props.fallback}</OrElse.Provider>;
    }
  };
}

export const WithFallback = <R extends unknown>(value: ValueIface<R>): Response<R> => {
  const Get = mkContext<NonNullable<R>>();
  const OrElse = mkContext<R>();
  const Provider = mkWithFallback({
    Get,
    OrElse,
    value,
  });
  return {
    Get,
    OrElse,
    Provider,
  };
};
