import stringify from 'fast-json-stable-stringify';

type ListenerType<T> = (value: T, prev: T) => void;

export interface ValueIface<T> {
  listeners: Set<ListenerType<T>>;
  get: () => T;
  set: (value: T) => void;
  reset: () => void;
  transform: (f: (current: T) => T) => void;
  addListener: (cb: ListenerType<T>) => ListenerType<T>;
  removeListener: (cb: ListenerType<T>) => void;
}

export function Value<T>(defaultValue: T): ValueIface<T> {
  let current: T = defaultValue;
  let currentStr: string = stringify(current);

  const listeners: ValueIface<T>['listeners'] = new Set();

  const get: ValueIface<T>['get'] = () => current;

  const set: ValueIface<T>['set'] = (value) => {
    transform(() => value);
  };

  const reset: ValueIface<T>['reset'] = () => {
    set(defaultValue);
  };

  const transform: ValueIface<T>['transform'] = (f) => {
    const prev = current;
    const next = f(prev);
    const different = typeof next != 'function' ? currentStr !== stringify(next) : prev != next;
    if (different) {
      current = next;
      currentStr = stringify(next);
      listeners.forEach((listener) => {
        listener(next, prev);
      });
    }
  };

  const addListener: ValueIface<T>['addListener'] = (cb) => {
    listeners.add(cb);
    return cb;
  };

  const removeListener: ValueIface<T>['removeListener'] = (cb) => {
    listeners.delete(cb);
  };

  return {
    listeners,
    get,
    set,
    reset,
    transform,
    addListener,
    removeListener,
  };
}
