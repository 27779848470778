import { ListenerType, readonly } from './lib';
import stringify from 'fast-json-stable-stringify';

export interface ValueIface<T> {
  listeners: Set<ListenerType<T>>;
  get: () => T;
  set: (value: T) => void;
  reset: () => void;
  transform: (f: (current: T) => T) => void;
  addListener: (cb: ListenerType<T>) => ListenerType<T>;
  removeListener: (cb: ListenerType<T>) => void;
}

export function Value<T>(defaultValue: T): ValueIface<T> {
  let current: T = readonly(defaultValue);
  let currentStr: string = stringify(current);

  const listeners: ValueIface<T>['listeners'] = new Set();

  const get: ValueIface<T>['get'] = () => current;

  const set: ValueIface<T>['set'] = (value) => {
    transform(() => value);
  };

  const reset: ValueIface<T>['reset'] = () => {
    set(defaultValue);
  };

  const transform: ValueIface<T>['transform'] = (f) => {
    const prev = current;
    const next = readonly(f(prev));
    if (currentStr !== stringify(next)) {
      current = next;
      currentStr = stringify(next);
      listeners.forEach((listener) => {
        listener(next, prev);
      });
    }
  };

  const addListener: ValueIface<T>['addListener'] = (cb) => {
    listeners.add(cb);
    return cb;
  };

  const removeListener: ValueIface<T>['removeListener'] = (cb) => {
    listeners.delete(cb);
  };

  return readonly<ValueIface<T>>({
    listeners,
    get,
    set,
    reset,
    transform,
    addListener,
    removeListener,
  });
}
