import * as React from 'react';
import { ValueIface } from './Value';
import { ListenerType } from './lib';

export function useValue<T>(observable: ValueIface<T>): T {
  const [value, setValue] = React.useState<Readonly<T>>(observable.get());

  React.useEffect(() => {
    const cb: ListenerType<T> = (value) => {
      setValue(value);
    };
    observable.addListener(cb);
    setValue(observable.get());
    return () => {
      observable.removeListener(cb);
    };
  }, []);

  return value;
}
