/**
 * Shop
 */

export interface ShopExternalProfileIface {
  id: string;
  username: string;
  colloquialName: null | string;
  photoMediaId: null | string;
  businessId: null | string;
}

export interface ShopExternalMediaIface {
  id: string;
  url: string;
  width: number;
  height: number;
}

export interface ShopExternalCityIface {
  id: string;
  name: string;
  administrativeAreaCode: string;
  countryCode: string;
}

export interface ShopExternalProfileLinkIface {
  id: string;
  profileId: string;
  url: string;
}

export interface ShopExternalProfileListingPreviewIface {
  profileId: string;
  id: string;
  brand: string | null;
  title: string | null;
  price: number | null;
  coverPhotoUrl: string | null;
}

export enum ShopExternalProfileListingCondition {
  NewWithTags = 0,
  NewWithoutTags = 1,
  NewWithDefects = 2,
  PreOwned = 3,
  Poor = 4,
}

export enum ShopExternalProfileListingColor {
  Red = 0,
  Pink = 1,
  Orange = 2,
  Yellow = 3,
  Green = 4,
  Blue = 5,
  Purple = 6,
  Gold = 7,
  Silver = 8,
  Black = 9,
  Gray = 10,
  Cream = 11,
  Brown = 12,
  Tan = 13,
  White = 14,
}

export enum InstitutionEnum {
  Poshmark = 0,
  Mercari = 1,
  Depop = 2,
  Tradesy = 3,
  Ebay = 4,
  Grailed = 7,
  Vendoo = 11,
}

export interface ShopExternalProfileListingIface {
  id: string;
  mediaIds: string[];
  title: string | null;
  description: string | null;
  price: number | null;
  condition: ShopExternalProfileListingCondition | null;
  brand: string | null;
  colors: ShopExternalProfileListingColor[];
  sizeId: string | null;
  marketplaceUrls: {
    institution: InstitutionEnum;
    url: string;
  }[];
}

export enum ShopExternalDirectListingInventoryFormatType {
  BundleLot = 0,
  SingleItem = 1,
}

export enum ShopExternalDirectListingBuyingFormatType {
  FixedPrice = 0,
  Auction = 1,
}

export enum ShopExternalDirectListingConditionType {
  Nwt = 0,
  Nwot = 1,
  Used = 2,
  Damaged = 3,
}

export enum ShopExternalDirectListingAvailabilityType {
  Draft = 0,
  ForSale = 1,
  Sold = 2,
  Canceled = 3,
}

export interface ShopExternalListingIface {
  id: string;
  businessId: string;
  availableAt: number;
  availabilityType: ShopExternalDirectListingAvailabilityType;
  inventoryFormatType: ShopExternalDirectListingInventoryFormatType;
  buyingFormatType: ShopExternalDirectListingBuyingFormatType;
  itemCountRangeStart: number;
  itemCountRangeEnd: number;
  description: string;
  photoIds: string[];
  categoryIds: string[];
  conditions: ShopExternalDirectListingConditionType[];
  customFeaturedBrands: string[];
  fixedPricePriceCents: number | null;
}

export enum ShopExternalInvoiceStatusType {
  Draft = 0,
  Open = 1,
  Paid = 2,
  Uncollectible = 3,
  Void = 4,
  Paying = 5,
}

export interface ShopExternalInvoiceIface {
  id: string;
  dueAt: number;
  description: string;
  status: ShopExternalInvoiceStatusType;
  payeeBusinessId: string;
  payorBusinessId: string | null;
  derivativePretaxTotal: number;
}
