import localForage from 'localforage';

let blogPostPlacement: string | undefined;

export const setLastBlogPostPlacement = (value: string) => {
  blogPostPlacement = value;
  localForage.setItem('x-sp-blog-post-placement', value);
};

export const getLastBlogPostPlacement = (): Promise<string | null> => {
  return localForage.getItem<string>('x-sp-blog-post-placement').then((_) => _ ?? blogPostPlacement ?? null);
};

let blogPostPath: string | undefined;

export const setLastBlogPostPath = (value: string) => {
  blogPostPath = value;
  localForage.setItem('x-sp-blog-post-path', value);
};

export const getLastBlogPostPath = (): Promise<string | null> => {
  return localForage.getItem<string>('x-sp-blog-post-path').then((_) => _ ?? blogPostPath ?? null);
};

let adsGoogleCampaign: string | undefined;

export const setLastAdsGoogleCampaign = (value: string) => {
  adsGoogleCampaign = value;
  localForage.setItem('x-sp-ads-g-campaign', value);
};

export const getLastAdsGoogleCampaign = (): Promise<string | null> => {
  return localForage.getItem<string>('x-sp-ads-g-campaign').then((_) => _ ?? adsGoogleCampaign ?? null);
};

let sessionReferralCode: string | undefined;

export const setReferralCode = (value: string) => {
  sessionReferralCode = value;
  localForage.setItem('x-sp-r-code', value);
};

export const getReferralCode = (): Promise<string | null> => {
  return localForage.getItem<string>('x-sp-r-code').then((_) => _ ?? sessionReferralCode ?? null);
};
