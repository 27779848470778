import * as React from 'react';
import { mkContext } from './mkContext';
import { AsyncStateValue, AsyncStateValueIface } from 'src/util/observe/AsyncStateValue';
import { Value, ValueIface } from 'src/util/observe/Value';
import { LookupStoreIface } from 'src/util/observe/LookupStoreValue';
import { appendUniqueStrings } from 'src/util/array';
import { Page } from 'src/util/page';

interface TotalIface {
  relation?: 'eq' | 'gte';
  value?: number;
}

interface Response<T> {
  FetchPage: React.Context<() => Promise<void>>;
  Reset: React.Context<() => Promise<void>>;
  Items: React.Context<T[]>;
  Provider: React.FC<PropsIface<T>>;
  internals: {
    AsyncState: React.Context<AsyncStateValueIface>;
    Cursor: React.Context<ValueIface<string | null>>;
    Total: React.Context<ValueIface<TotalIface | null>>;
    ItemIds: React.Context<ValueIface<string[]>>;
    LookupStore: React.Context<ValueIface<LookupStoreIface<T>>>;
  };
}

interface MkWithPaginationStateArgs<T> {
  AsyncState: React.Context<AsyncStateValueIface>;
  Cursor: React.Context<ValueIface<string | null>>;
  Total: React.Context<ValueIface<TotalIface | null>>;
  FetchPage: React.Context<() => Promise<void>>;
  Items: React.Context<T[]>;
  ItemIds: React.Context<ValueIface<string[]>>;
  LookupStore: React.Context<ValueIface<LookupStoreIface<T>>>;
  Reset: React.Context<() => Promise<void>>;
  idSelector: (item: T) => string;
  lookupStoreValue: ValueIface<LookupStoreIface<T>>;
}

interface PropsIface<T> {
  fetchPage: (cursor: string | null) => Promise<Page<T>>;
}

function mkWithPaginationState<T extends unknown>(args: MkWithPaginationStateArgs<T>): React.FC<PropsIface<T>> {
  const { AsyncState, Cursor, Total, FetchPage, Items, ItemIds, Reset, LookupStore } = args;
  return (props) => {
    const [asyncStateValue] = React.useState<AsyncStateValueIface>(AsyncStateValue());
    const [totalValue] = React.useState<ValueIface<TotalIface | null>>(Value<TotalIface | null>(null));
    const [cursorValue] = React.useState<ValueIface<string | null>>(Value<string | null>(null));
    const [itemIdsValue] = React.useState<ValueIface<string[]>>(Value<string[]>([]));
    const [items, setItems] = React.useState<T[]>([]);
    const fetchRequestIdRef = React.useRef<number>(0);

    React.useEffect(() => {
      const regenerateItems = (): void => {
        const itemIds = itemIdsValue.get();
        const lookupStore = args.lookupStoreValue.get();
        const newItems = itemIds.flatMap((itemId) => {
          if (lookupStore[itemId] != null) {
            return [lookupStore[itemId]];
          } else {
            return [];
          }
        });
        setItems(newItems);
      };
      const itemIdsListener = itemIdsValue.addListener(regenerateItems);
      const lookupStoreListener = args.lookupStoreValue.addListener(regenerateItems);
      return () => {
        itemIdsValue.removeListener(itemIdsListener);
        args.lookupStoreValue.removeListener(lookupStoreListener);
      };
    }, []);

    const fetchPage: (reset?: boolean) => Promise<void> = React.useCallback(
      async (reset) => {
        if (reset == true || asyncStateValue.value.get().status !== 'pending') {
          const requestId = (fetchRequestIdRef.current += 1);
          const p = props.fetchPage(cursorValue.get());
          asyncStateValue.subscribe(p);
          await p.then((page) => {
            if (fetchRequestIdRef.current > requestId) {
              return;
            }
            const pageItemIds = page.items.map((_) => args.idSelector(_));
            if (reset == true) {
              itemIdsValue.set(pageItemIds);
            } else {
              itemIdsValue.transform((itemIds) => {
                return appendUniqueStrings(itemIds, pageItemIds);
              });
            }
            cursorValue.set(page.cursor ?? null);
            totalValue.set(page.total ?? null);
          });
        }
      },
      [props.fetchPage]
    );

    const reset: () => Promise<void> = React.useCallback(() => {
      cursorValue.set(null);
      totalValue.set(null);
      return fetchPage(true);
    }, [fetchPage]);

    React.useEffect(() => {
      fetchPage();
    }, [fetchPage]);

    return (
      <FetchPage.Provider value={fetchPage}>
        <Reset.Provider value={reset}>
          <AsyncState.Provider value={asyncStateValue}>
            <Cursor.Provider value={cursorValue}>
              <Total.Provider value={totalValue}>
                <ItemIds.Provider value={itemIdsValue}>
                  <Items.Provider value={items}>
                    <LookupStore.Provider value={args.lookupStoreValue}>{props.children}</LookupStore.Provider>
                  </Items.Provider>
                </ItemIds.Provider>
              </Total.Provider>
            </Cursor.Provider>
          </AsyncState.Provider>
        </Reset.Provider>
      </FetchPage.Provider>
    );
  };
}

interface Args<T> {
  idSelector: (item: T) => string;
  lookupStoreValue: ValueIface<LookupStoreIface<T>>;
}

export const WithPaginationState = <T extends unknown>(args: Args<T>): Response<T> => {
  const AsyncState = mkContext<AsyncStateValueIface>();
  const Cursor = mkContext<ValueIface<string | null>>();
  const Total = mkContext<ValueIface<TotalIface | null>>();
  const FetchPage = mkContext<() => Promise<void>>();
  const Items = mkContext<T[]>();
  const ItemIds = mkContext<ValueIface<string[]>>();
  const LookupStore = mkContext<ValueIface<LookupStoreIface<T>>>();
  const Reset = mkContext<() => Promise<void>>();
  const Provider = mkWithPaginationState({
    AsyncState,
    Cursor,
    Total,
    FetchPage,
    Items,
    ItemIds,
    LookupStore,
    Reset,
    idSelector: args.idSelector,
    lookupStoreValue: args.lookupStoreValue,
  });
  return {
    FetchPage,
    Reset,
    Items,
    Provider,
    internals: {
      AsyncState,
      Cursor,
      Total,
      ItemIds,
      LookupStore,
    },
  };
};
