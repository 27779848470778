import * as React from 'react';
import { ValueIface } from 'src/util/observe/Value';

export function useValue<T>(observable: ValueIface<T>): T {
  const [value, setValue] = React.useState<T>(observable.get());

  React.useEffect(() => {
    const listener = observable.addListener((value) => {
      setValue(value);
    });
    setValue(observable.get());
    return () => {
      observable.removeListener(listener);
    };
  }, []);

  return value;
}
