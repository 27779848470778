import { Value, ValueIface } from './Value';

export function DerivedValue<T>(transform: (prev: undefined | T) => T, deps: ValueIface<any>[]): ValueIface<T> {
  const value = Value<T>(transform(undefined));
  const depList = Object.values<ValueIface<any>>(deps);
  const cb = () => value.transform(transform);
  depList.forEach((dep) => {
    dep.addListener(cb);
  });
  return value;
}
