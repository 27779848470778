import * as Observe from 'src/util/observe';
import * as StatePublicTypes from 'src/statePublic/types';

export const ExternalProfilesValue = Observe.LookupStoreValue<StatePublicTypes.ShopExternalProfileIface>();
export const ExternalMediaValue = Observe.LookupStoreValue<StatePublicTypes.ShopExternalMediaIface>();
export const ExternalProfileLinksValue = Observe.LookupStoreValue<StatePublicTypes.ShopExternalProfileLinkIface>();
export const ExternalProfileListingsValue =
  Observe.LookupStoreValue<StatePublicTypes.ShopExternalProfileListingIface>();
export const ExternalListingsValue = Observe.LookupStoreValue<StatePublicTypes.ShopExternalListingIface>();
export const ExternalInvoicesValue = Observe.LookupStoreValue<StatePublicTypes.ShopExternalInvoiceIface>();

export const ExternalProfileListingPreviewPaginationState =
  Observe.React.WithPaginationState<StatePublicTypes.ShopExternalListingIface>({
    idSelector: (_) => _.id,
    lookupStoreValue: ExternalListingsValue,
  });
