import * as React from 'react';
import * as StatePublic from 'src/statePublic';
import { useRouter } from 'next/router';

export const useSyncQueryParams = () => {
  const router = useRouter();
  React.useEffect(() => {
    StatePublic.Observe.App.QueryParamsValue.set(router.query);
  }, [router.query]);
};
