import 'url-search-params-polyfill';
import * as React from 'react';
import * as SessionStore from 'src/clients/session';
import Head from 'next/head';
import { useSyncQueryParams } from 'src/hooks/router/useSyncQueryParams';
import { useRouter } from 'next/router';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  useSyncQueryParams();

  React.useEffect(() => {
    if (window.fbq != null) {
      window.fbq('track', 'PageView');
    }
    const searchParams = new URLSearchParams((router.asPath || '').split(/\?/)[1]);
    const referralCode = searchParams.get('r');
    const adsGoogleCampaign = searchParams.get('ag');
    const blogPostPath = searchParams.get('blogref');
    const blogPostPlacement = searchParams.get('blogidx');
    const cb = async () => {
      const [storedReferralCode, storedLastAdsGoogleCampaign, storedLastBlogPostPath, storedLastBlogPostPlacement] =
        await Promise.all([
          SessionStore.getReferralCode(),
          SessionStore.getLastAdsGoogleCampaign(),
          SessionStore.getLastBlogPostPath(),
          SessionStore.getLastBlogPostPlacement(),
        ]);
      if (Boolean(referralCode) && storedReferralCode == null) {
        SessionStore.setReferralCode(referralCode);
      }
      if (Boolean(adsGoogleCampaign) && storedLastAdsGoogleCampaign == null) {
        SessionStore.setLastAdsGoogleCampaign(adsGoogleCampaign);
      }
      if (Boolean(blogPostPath) && storedLastBlogPostPath == null) {
        SessionStore.setLastBlogPostPath(blogPostPath);
      }
      if (Boolean(blogPostPlacement) && storedLastBlogPostPlacement == null) {
        SessionStore.setLastBlogPostPlacement(blogPostPlacement);
      }
    };
    cb();
  }, [router.asPath]);

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
