import { Value, ValueIface } from './Value';
import { v4 as uuidv4 } from 'uuid';

export interface AsyncStateValueIface {
  subscribe: (p: Promise<unknown>) => void;
  value: ValueIface<AsyncStateIface>;
}

interface AsyncStateIface {
  status: 'uninitiated' | 'success' | 'failed' | 'pending';
  at: number;
}

export function AsyncStateValue(): AsyncStateValueIface {
  let _requestId: string | undefined;

  const value = Value<AsyncStateIface>({
    status: 'uninitiated',
    at: Date.now(),
  });

  const subscribe: AsyncStateValueIface['subscribe'] = (p) => {
    const promiseId = uuidv4();
    _requestId = promiseId;
    value.set({
      status: 'pending',
      at: Date.now(),
    });
    return p
      .then(() => {
        if (promiseId !== _requestId) {
          return;
        }
        value.set({
          status: 'success',
          at: Date.now(),
        });
      })
      .catch(() => {
        if (promiseId !== _requestId) {
          return;
        }
        value.set({
          status: 'failed',
          at: Date.now(),
        });
      });
  };

  return {
    subscribe,
    value,
  };
}
