import { readonly } from './lib';
import { Value, ValueIface } from './Value';

export interface AsyncValueIface<T> {
  resolve: (p: Promise<T>) => Promise<undefined | AsyncStateType<T>>;
  value: Readonly<ValueIface<undefined | AsyncStateType<T>>>;
  getResolvedState(): undefined | ResolvedAsyncStateType<T>;
  getCaughtState(): undefined | CaughtAsyncStateType<T>;
  getPendingState(): undefined | PendingAsyncStateType<T>;
  getResolvedValue(): undefined | T;
  getFallbackValue(): undefined | T;
  getValueWithFallback(): undefined | T;
}

export type ResolvedAsyncStateType<T> = {
  status: 'resolved';
  updatedAt: Date;
  value: T;
};

export type CaughtAsyncStateType<T> = {
  status: 'caught';
  updatedAt: Date;
  error: Error;
  lastValue?: T;
};

export type PendingAsyncStateType<T> = {
  status: 'pending';
  updatedAt: Date;
  lastValue?: T;
};

export type AsyncStateType<T> = ResolvedAsyncStateType<T> | CaughtAsyncStateType<T> | PendingAsyncStateType<T>;

function selectValue<T>(stateType: undefined | AsyncStateType<T>): undefined | T {
  switch (stateType?.status) {
    case 'resolved':
      return stateType.value;
    case 'caught':
      return stateType.lastValue;
    case 'pending':
      return stateType.lastValue;
    default:
      return undefined;
  }
}

interface Args<T> {
  merge?: (prev: undefined | Readonly<T>, next: Readonly<T>) => T;
}

export function AsyncValue<T>(args: Args<T>): Readonly<AsyncValueIface<T>> {
  const value = Value<undefined | AsyncStateType<T>>(undefined);

  const resolve: AsyncValueIface<T>['resolve'] = (p) => {
    value.transform((current) => {
      return {
        status: 'pending',
        updatedAt: new Date(),
        lastValue: selectValue<T>(current),
      };
    });
    return p
      .then((r) => {
        value.transform((current) => {
          const currentValue = selectValue<T>(current);
          const nextCurrentValue = args.merge != null ? args.merge(readonly(currentValue), readonly(r)) : readonly(r);
          return {
            status: 'resolved',
            updatedAt: new Date(),
            value: nextCurrentValue,
          };
        });
        return value.get();
      })
      .catch((e) => {
        value.transform((current) => {
          return {
            status: 'caught',
            updatedAt: new Date(),
            error: e,
            lastValue: selectValue<T>(current),
          };
        });
        return value.get();
      });
  };

  const getResolvedState = (): ResolvedAsyncStateType<T> | undefined => {
    const asyncState = value.get();
    if (asyncState?.status === 'resolved') {
      return asyncState;
    } else {
      return undefined;
    }
  };

  const getCaughtState = (): CaughtAsyncStateType<T> | undefined => {
    const asyncState = value.get();
    if (asyncState?.status === 'caught') {
      return asyncState;
    } else {
      return undefined;
    }
  };

  const getPendingState = (): PendingAsyncStateType<T> | undefined => {
    const asyncState = value.get();
    if (asyncState?.status === 'pending') {
      return asyncState;
    } else {
      return undefined;
    }
  };

  const getResolvedValue = (): T | undefined => {
    const asyncState = value.get();
    if (asyncState?.status === 'resolved') {
      return asyncState.value;
    } else {
      return undefined;
    }
  };

  const getFallbackValue = (): T | undefined => {
    const asyncState = value.get();
    if (asyncState?.status === 'pending' || asyncState?.status === 'caught') {
      return asyncState.lastValue;
    } else {
      return undefined;
    }
  };

  const getValueWithFallback = (): T | undefined => {
    return getResolvedValue() ?? getFallbackValue();
  };

  return readonly<AsyncValueIface<T>>({
    resolve,
    value,
    getResolvedState,
    getCaughtState,
    getPendingState,
    getResolvedValue,
    getFallbackValue,
    getValueWithFallback,
  });
}
