/* (aaron): Copied from superposher-servce */
export const groupBy = <T, K extends string>(arr: T[], getKey: (obj: T) => K): Record<K, T[]> => {
  return arr.reduce<Record<K, T[]>>((accumulator, current) => {
    const key = getKey(current);
    accumulator[key] = [...(accumulator[key] ?? []), current];
    return accumulator;
  }, {} as Record<K, T[]>);
};

export const arrayToMap = <T>(arr: T[], getKey: (obj: T) => string): Record<string, T> => {
  const entityMap: Record<string, T> = {};
  for (let i = 0; i < arr.length; i++) {
    entityMap[getKey(arr[i])] = arr[i];
  }
  return entityMap;
};

export function appendUniqueStrings(array1: string[], array2: string[]): string[] {
  const result: string[] = ([] as string[]).concat(array1);
  array2.forEach((element) => {
    const resultValue = result.find((id) => id === element);
    if (resultValue == null) {
      result.push(element);
    }
  });
  return result;
}

export function prependUniqueStrings(array1: string[], array2: string[]): string[] {
  const result: string[] = ([] as string[]).concat(array1);
  const prepending: string[] = [];

  array2.forEach((element) => {
    const resultValue = result.find((id) => id === element);
    if (resultValue == null) {
      prepending.push(element);
    }
  });
  return prepending.concat(result);
}

export const distinct = (array: any[]) => {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

/**
 * Rotates the array to start at the pivot point.
 */
export const rotate = <T>(arr: T[], pivot: number) => {
  return arr.slice(pivot, arr.length).concat(arr.slice(0, pivot));
};

/**
 * Groups an array of objects according to a function that returns an indexable value, mapping the final value.
 */
export const groupByMap = <T, K extends string | number, V>(
  arr: T[],
  getKey: (obj: T) => K,
  mapper: (obj: T) => V
): Record<K, V[]> => {
  return arr.reduce<Record<K, V[]>>((accumulator, current) => {
    const key = getKey(current);
    accumulator[key] = [...(accumulator[key] ?? []), mapper(current)];
    return accumulator;
  }, {} as Record<K, V[]>);
};

/**
 * Gets elements in an array distinct on a specific key
 */
export const distinctOn = <T, K extends string | number>(arr: T[], getKey: (obj: T) => K): T[] => {
  return arr.filter((val, idx, arr) => idx === arr.findIndex((_) => getKey(_) == getKey(val)));
};

/**
 * Split an array into batches of fixed size
 */
export const batched = <T>(arr: T[], batchSize: number): T[][] => {
  // need to spread first so we can iterate over the array
  return [...Array(Math.ceil(arr.length / batchSize))]
    .map((_, index) => index * batchSize)
    .map((_) => arr.slice(_, _ + batchSize));
};

/**
 * Sort an array without mutating the original
 */
export const sort = <T>(arr: T[], compare?: (a: T, b: T) => number): T[] => {
  if (compare) {
    return [...arr].sort(compare);
  }
  return [...arr].sort();
};

/**
 * Join two arrays of equal length
 */
export const zip = <A, B>(a: A[], b: B[]): [A, B][] => {
  return a.map((v, i) => [v, b[i]]);
};

export const shuffle = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};
